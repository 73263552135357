<template>
  <div class="banner-container">
    <div>
      <div class="logo" style="padding: 30px">
        <img src="@/assets/eSignsLogo.svg" style="height: 25px" />
      </div>
      <div class="big-image" style="text-align: center; margin: 20px">
        <img src="@/assets/LoginPageImage.svg" :style="isMobile ? 'display: none' : 'width: 300px'" />
      </div>
      <div class="text">
        <h2>Establish automation workflow with all applications</h2>
        <br />
        <p>
          Streamline the automation of your preferred apps<br />
          and devices in a fast and effortless manner
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerComponent',
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    checkMobileView() {
      this.isMobile = window.innerWidth <= 768; // Mobile threshold
    },
  },
  mounted() {
    this.checkMobileView(); // Check on load

    // Listen for window resize events
    window.addEventListener('resize', this.checkMobileView);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobileView);
  },
};
</script>

<style scoped>
.banner-container {
  margin: 0px;
  padding: 0px;
  background-color: #1565d8;
  height: 100%;
  font-family: Inter, sans-serif;
  font-weight: bolder;
}

.text {
  text-align: center;
}

.text p {
  font-size: 1.25rem !important;
  color: white;
  text-align: center;
}

.text h2 {
  font-size: 1.25rem !important;
  color: white;
  text-align: center;
}

@media (max-width: 768px) {
  .banner-container {
    width: 100vw;
    max-height: 42vh !important;
  }
}
</style>
