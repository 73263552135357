import axios from "axios";
import appConfig from "@/config/app";

// initial state
const initiateState = () => ({
  loginResponse: null,
});

const state = initiateState();

const getters = {
  getSsoLoginResponse(state) {
    return state.loginResponse;
  },
};
const mutations = {
  setSsoLoginResponse: (state, data) => {
    state.loginResponse = data;
  },
};

const actions = {
  async ssouserLogin({ commit }, params) {
    try {
      const response = await axios.post(
        `${appConfig.app.connectorAppApi}/sso/sso-login`,
        params
      );
      commit("setSsoLoginResponse", response.data);
    } catch (err) {
      commit("setSsoLoginResponse", err.response);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
