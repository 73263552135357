<template>
  <div></div>
</template>
<script>
import store from "@/store/index";
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("sso", ["getSsoLoginResponse"]),
  },
  async mounted() {
    this.extractQueryFromUrl();
  },
  methods: {
    async extractQueryFromUrl() {
      let query = this.$route.query;
      await store.dispatch("sso/ssouserLogin", query);
      localStorage.setItem("user-info", JSON.stringify(this.getSsoLoginResponse.data));
      this.$router.push(this.$route.query.redirect || "/connector");
    },
  },
};
</script>
