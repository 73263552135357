<template>
  <el-row class="connector-logo">
    <el-col :span="24">
      <div class="mt-5">
        <img
          src="@/assets/connector_icons/AutomationIcon.svg"
          alt="Connector app icon"
          class="image"
        />
      </div>
      <h1 class="text-center mb-4 custom-heading">Create Your Automation Work Flow</h1>
      <p class="text-center text-dark custom-text">
        The eSigns automation workflow allows you to centralize the creation<br />
        and management of all your automation workflows in a single location.
      </p>
      <div class="text-center">
        <el-button
          class="create-btn px-4 ml-2 create-button"
          type="primary"
          style="cursor: pointer"
          @click="openNewWorkflow"
        >
          <el-icon><CirclePlus /></el-icon>
          <span>Create New Automation</span>
        </el-button>
      </div>

      <el-dialog
        ref="newAutomationflowDialog"
        title="Create New Automation"
        v-model="newAutomationflowModal"
        class="doc-modal create-workflow-popup"
        v-loading="modalLoading"
        :element-loading-text="loadingText"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :visible="newAutomationflowModal"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="destroyCreateModal"
      >
        <el-row>
          <el-col :span="24">
            <div class="mb-2">
              <el-input
                type="text"
                size="large"
                placeholder="Enter Automation Title"
                v-model="AutomationTitleName"
              ></el-input>
            </div>
            <div style="float: left">
              <el-checkbox>
                Is this automation dependent?
                <el-tooltip
                  effect="light"
                  placement="top"
                  shadow="always"
                  class="base-box-item"
                  ><template #content>
                    <div class="tooltip-class">
                      If you select this option, actions within this automation will be
                      linked,<br />
                      where the occurrence of one action depends on another event
                      happening.
                    </div>
                  </template>
                  <el-icon><InfoFilled /></el-icon>
                </el-tooltip>
              </el-checkbox>
            </div>
          </el-col>
        </el-row>

        <template #footer>
          <div class="dialog-footer">
            <el-button size="large" @click="destroyCreateModal">Cancel</el-button>
            <el-button type="primary" size="large" @click="createAutomationflow"
              >Confirm</el-button
            >
          </div>
        </template>
      </el-dialog>
    </el-col>
  </el-row>
</template>

<script>
import store from "@/store/index";
import { mapGetters } from "vuex";
export default {
  name: "createAutomation",
  components: {},
  data() {
    return {
      newAutomationflowModal: false,
      modalLoading: false,
      loadingText: "",
      AutomationTitleName: "",
    };
  },
  computed: {
    ...mapGetters(["getAllFlowsByUserId", "getAutomationFlow"]),
  },
  mounted() {
    // Dynamically load jQuery
    let jQueryScript = document.createElement('script');
    jQueryScript.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
    jQueryScript.onload = () => {
      // Once jQuery is loaded, load chatbot.js
      (function(w, d, s) {
        w.chatbotConfig = {
          botId: '202',
          token: '$2y$10$WsZaqeE1nS8DLfhafDamOujB3anf.oOZgMVKAGbHzisEkR3alWYWi'
        };
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s);
        j.async = true;
        j.src = 'https://nimbleproperty.net/botAI/chatbot.js';
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script');
    };
   
    // Append the jQuery script to the document
    document.head.appendChild(jQueryScript);
  },

  methods: {
    async createAutomationflow() {
      let params = {
        workflow_name: this.AutomationTitleName,
      };
      await store.dispatch("createAutomationFlow", params);
      if (
        this.getAutomationFlow &&
        this.getAutomationFlow.data &&
        this.getAutomationFlow.data._id
      ) {
        this.$notify.success({
          title: "Success",
          message: "Automation Flow created successfully.",
        });
        this.$router.push({
          path: `/connector/workflow/${this.getAutomationFlow.data._id}`,
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in creating Automation flow",
        });
      }
      this.loading = false;
    },
    openNewWorkflow() {
      this.newAutomationflowModal = true;
    },
    destroyCreateModal() {
      this.newAutomationflowModal = false;
    },
  },
};
</script>

<style lang="scss">
#connectorlogo {
  padding-top: 10%;
}
.custom-heading {
  font-size: 24px;
  font-family: "Arimo", sans-serif;
  font-weight: 500;
  color: black;
}
.custom-text {
  font-size: 14px;
  font-family: "Arimo", sans-serif;
  line-height: 20px;
}
.create-button {
  height: 40px;
}
.tooltip-class {
  font-size: 16px;
  color: #4c4b4e;
  background-color: white !important;
}
.base-box-item {
  height: 100%;
  display: flex;
  justify-content: center;
}

.doc-modal {
  &.create-workflow-popup {
    & > .el-dialog {
      width: 430px !important;
      @media (max-width: 500px) {
        width: 310px !important;
      }
    }
  }
}
</style>
