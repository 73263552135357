<template>
  <el-tab-pane
    label="Account"
    key="account"
    name="account"
    :disabled="appInfo.event_id ? false : true"
  >
    <el-main style="flex-grow: 1">
      <el-card shadow="never">
        <div>
          <el-radio-group v-model="isExisted" direction="horizontal">
            <el-radio label="Select Existing Connection" size="large">
              <span class="drawer-card-header">
                Choose Existing Connection
              </span>
            </el-radio>
            <el-radio label="Add New Connection" size="large">
              <span class="drawer-card-header"> Create New Connection </span>
            </el-radio>
          </el-radio-group>
        </div>
        <br />

        <el-col
          v-if="isExisted === 'Select Existing Connection'"
          style="color: #606266; font-size: 16px; margin-top: 20px"
        >
          Select Connections
        </el-col>

        <el-col
          v-if="isExisted === 'Select Existing Connection'"
          class="select-connections"
          style="color: #606266; font-size: 16px"
        >
          <el-select
            v-model="appInfo.app_connection_id"
            placeholder="Select any connection"
            size="large"
            v-if="isExisted === 'Select Existing Connection'"
            @change="selectExisistingConnection"
          >
            <el-option
              v-for="connection in connections"
              :key="connection._id"
              :label="connection.name"
              :value="connection._id"
            ></el-option>
          </el-select>

          <el-dropdown
            v-if="isExisted === 'Select Existing Connection'"
            trigger="click"
            style="margin-left: 2%"
          >
            <el-icon class="more-icon">
              <MoreFilled />
            </el-icon>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  @click="updateConnectionDialog = true"
                  :disabled="!connectionModel"
                >
                  <el-icon>
                    <Edit />
                  </el-icon>
                  Update Connection</el-dropdown-item
                >
                <el-dropdown-item
                  @click="deleteConnectionDialog = true"
                  :disabled="!connectionModel"
                >
                  <el-icon>
                    <View />
                  </el-icon>
                  Delete Connection</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-col>

        <el-col v-else class="new-name">
          <span style="color: red"> * </span>
          New Connection Name
          <el-input
            size="large"
            type="textarea"
            v-model="textarea"
            style="margin-bottom: 15px"
          >
          </el-input>
          <div v-if="isExisted !== 'Select Existing Connection'">
            <el-row
              v-if="appInfo.app_id.input_fields"
              class="flex-row"
              style="max-height: 40vh; overflow-y: auto"
            >
              <el-col>
                <el-form label-position="top">
                  <el-form-item
                    v-for="(label, index) in appInfo.app_id.input_fields"
                    :key="index"
                    :label="label.label"
                    :prop="label.key"
                  >
                    <el-input
                      size="large"
                      :placeholder="label.placeHolder"
                      v-model="label.value"
                    ></el-input>
                    <span v-html="label.description"> </span>
                  </el-form-item>

                  <el-footer class="drawer-footer">
                    <el-button
                      shadow="always"
                      class="custom-button btn-clr"
                      style="margin-bottom: 10%"
                      v-if="isExisted !== 'Select Existing Connection'"
                      type="primary"
                      size="medium"
                      @click="verifyAndSaveCredentials"
                      >Save the connection</el-button
                    >
                  </el-footer>
                </el-form>
              </el-col>
            </el-row>
            <el-row v-else>
              <el-button
                type="primary"
                @click="connectToOuath"
                size="large"
                class="oauth-connect-button"
                >Connect with {{ appInfo.app_id.app_name_label }}</el-button
              ><br />
              <span class="drawer-title-p"
                >Please authenticate your
                {{ appInfo.app_id.app_name_label }} app to allow access to
                Connector.</span
              >
            </el-row>
          </div>
        </el-col>
        <el-dialog
          v-model="updateConnectionDialog"
          title="Update Connections"
          width="30%"
          draggable
        >
          <span class="textStyle"> Connection Name </span><br /><br />
          <el-input
            size="large"
            type="textarea"
            v-model="textareaOfUpdateDialog"
          ></el-input>
          <el-checkbox v-model="actionUpdateConnectionsData">
            Update Connections Data</el-checkbox
          ><br /><br />
          <div v-if="needConnectButton != true">
            <el-button
              type="primary"
              size="large"
              class="button-connect-drawer"
              :disabled="!actionUpdateConnectionsData"
              >Connect with {{ appInfo.app_id.app_name }}</el-button
            >
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="updateConnectionDialog = false"
                >Cancel</el-button
              >
              <el-button type="primary" @click="updateConnectionNameEvent">
                Confirm
              </el-button>
            </span>
          </template>
        </el-dialog>
        <el-dialog
          v-model="deleteConnectionDialog"
          title="Do you really want to delete this connection?"
          width="30%"
          draggable
        >
          This propsObject cannot be undone
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="deleteConnectionDialog = false"
                >Cancel</el-button
              >
              <el-button type="primary" @click="deleteConnectionEvent">
                Confirm
              </el-button>
            </span>
          </template>
        </el-dialog>
      </el-card>
    </el-main>
  </el-tab-pane>
</template>
<script>
import store from "@/store/index";
import { mapGetters } from "vuex";
import connectionsHelper from "@/mixins/connectionsHelper";
export default {
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isExisted: "Select Existing Connection",
      appInfo: this.step,
      connections: [],
      textarea: null,
      queryData: null,
    };
  },
  created() {
    this.interval = setInterval(this.checkLocalStorage, 2000);
  },
  computed: {
    ...mapGetters("globalApis", [
      "getAllConnections",
      "getOauthServerUrl",
      "getValidateAndSaveCredentials",
      "getOauthTokens",
    ]),
  },
  mixins: [connectionsHelper],
  watch: {
    async queryData(newValue) {
      if (newValue) {
        await this.getTokensUsingAuthorizationCode(newValue);
      }
    },
  },
  mounted() {
    this.fetchExistingConnections();
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods: {
    async checkLocalStorage() {
      let storageInfo = await localStorage.getItem("extractCode");
      this.queryData = await JSON.parse(storageInfo);
      await localStorage.removeItem("extractCode");
    },
    async fetchExistingConnections() {
      this.loading = true;
      let params = {
        appName: this.appInfo.app_id.app_name_key,
      };
      await store.dispatch("globalApis/fetchExisistingConnections", params);
      this.loading = false;
      if (this.getAllConnections.status) {
        if (this.getAllConnections.data && this.getAllConnections.data.length) {
          this.connections = this.getAllConnections.data;
          this.textarea =
            this.appInfo.app_id.app_name_label +
            " " +
            "#" +
            this.connections.length;
          this.$notify.success({
            title: "Success",
            message: this.getAllConnections.message,
          });
        } else {
          this.$notify.warning({
            title: "Warining",
            message: this.getAllConnections.message,
          });
        }
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getAllConnections.message,
        });
      }
    },
    async connectToOuath() {
      if (!this.textarea) {
        this.$message({
          showClose: true,
          message: "New Connection Name is required.",
          type: "error",
        });
        return;
      }
      await store.dispatch(
        "globalApis/getOauthAppServerUrl",
        this.appInfo.app_id.app_name_key
      );
      if (
        this.getOauthServerUrl &&
        this.getOauthServerUrl.data &&
        this.getOauthServerUrl.data.serviceProviderUrl
      ) {
        let url = `${this.getOauthServerUrl.data.serviceProviderUrl}&state=${this.appInfo._id}`;
        window.open(url, "_blank", "width=800,height=600");
      }
    },
    async getTokensUsingAuthorizationCode(value) {
      let params = {
        queryParams: value,
        appName: this.appInfo.app_id.app_name_key,
        connectionName: this.textarea,
      };
      this.loading = true;
      await store.dispatch("globalApis/getOauthTokens", params);
      this.loading = false;
      if (this.getOauthTokens.data && this.getOauthTokens.data._id) {
        this.appInfo.app_connection_id = this.getOauthTokens.data._id;
        this.$notify.success({
          title: "Success",
          message: "connection established successfully",
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in establishing connection",
        });
      }
    },
    async verifyAndSaveCredentials() {
      let params = {
        formData: this.appInfo.app_id.input_fields,
        connectionName: this.textarea,
        appName: this.appInfo.app_id.app_name_key,
      };
      this.loading = true;
      await this.$store.dispatch(
        "globalApis/validateAndSaveCredentials",
        params
      );
      this.loading = false;
      if (
        this.getValidateAndSaveCredentials.data &&
        this.getValidateAndSaveCredentials.data._id
      ) {
        this.appInfo.app_connection_id =
          this.getValidateAndSaveCredentials.data._id;
        this.connections.push(this.getValidateAndSaveCredentials.data);
        this.$notify.success({
          title: "Success",
          message: "connection established successfully",
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in establishing connection",
        });
      }
    },
  },
};
</script>
