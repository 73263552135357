<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="fixed-topbar">
          <TopbarDefault></TopbarDefault>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xl="1" :lg="1" :md="1">
        <div class="fixed-sidebar">
          <SidebarDefault :class="{ 'sidebar-visible': isVisible }"></SidebarDefault>
        </div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="23" :lg="23" :xl="23">
        <div
        >
          <main class="main-dashboard"
      v-loading="loading"
      element-loading-spinner="el-loading-spinner"
      element-loading-background="rgba(0, 0, 0, 0.7)">
            <router-view></router-view>
          </main>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SidebarDefault from "@/layouts/SidebarDefault.vue";
import TopbarDefault from "@/layouts/TopbarDefault.vue";

export default {
  name: "HomeView",
  components: {
    TopbarDefault,
    SidebarDefault,
  },
  data() {
    return {
      isVisible: true,
    };
  },
};
</script>

<style lang="scss">
.fixed-topbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.fixed-sidebar {
  position: fixed;
  top: 50px; /* Adjust this value according to your topbar's height */
  bottom: 0;
  z-index: 999;
}

.main-dashboard {
  margin-top: 25px;
  margin-left: 0px; /* Adjust this value based on the combined width of sidebar and margin */
  height: 100vh; /* Use the full viewport height */
  overflow-y: auto; /* Add vertical scroll if content exceeds viewport */
  padding-left: 10%;
  padding-right: 10%;
  box-sizing: border-box; /* Include padding in height calculation */
}

html,
body {
  height: 100%; /* Ensure full height */
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
}

@media (max-width: 991px) {
  .fixed-sidebar {
    display: none; /* Hide sidebar on smaller screens */
  }

  .main-dashboard {
    margin-left: 20px; /* Adjust main content margin for smaller screens */
  }
}
</style>
