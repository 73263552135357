<template>
  <div>
    <el-collapse v-model="accordion1Expanded">
      <el-collapse-item v-for="(fields, index) in mappingFields" :key="index">
        <template #title>
          <img :src="fields.logo" alt="logo" class="button-icon" />
          {{ fields.app_name }}
        </template>

        <div class="button-row">
          <el-button
            v-for="(item, index) in fields.fields"
            :key="index"
            class="button-with-icon"
            @click="toggleTag(item, field)"
          >
            <img :src="fields.logo" alt="logo" class="button-icon" />
            <template v-if="!item.showFullText">
              <span class="clrf">{{ item.label }}</span> :
              <span class="clrl">
                {{ item.value }}
              </span>
              <span v-if="getTotalLength(item.value)">
                <a @click="toggleTextVisibility(item)" class="clr">More</a>
              </span>
            </template>
            <template v-else>
              <span class="clrf">{{ item.label }}</span> :
              <span class="clrl">{{ item.value.join(", ") }}</span>
              <a @click="toggleTextVisibility(item)" class="clr">Less</a>
            </template>
          </el-button>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    mappingFields: {
      type: Array,
    },
  },
  data() {
    return {
      accordion1Expanded: false,
      allFields: null,
      loading: false,
      activeTab: "first",
    };
  },
  methods: {
    toggleTag(tag, field) {
      field.mappedFields.push(tag);
    },
    toggleText(tag) {
      tag.showFullText = !tag.showFullText;
    },
    removeTag(index, field) {
      field.mappedFields.splice(index, 1);
    },
    getTotalLength(valueArray) {
      let value = valueArray[0];
      if (typeof value === "string") {
        if (value.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    toggleTextVisibility(item) {
      item.showFullText = !item.showFullText;
    },
  },
};
</script>
<style scoped>
.artboard {
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap !important;
  overflow-y: auto;
  max-height: 440px;
}

.tag-textarea {
  width: calc(100% - 2px);
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  padding: 8px;
  resize: vertical;
}

.button-with-icon {
  display: flex;
  align-items: center;

  background-color: rgb(255, 255, 255);
  border: 1px solid #684fa9;
  box-shadow: rgb(149, 146, 142) 0px 0px 0px 1px;
  border-radius: 5px;
  cursor: pointer;
  padding: 15px;
  white-space: normal;
}

.button-with-icon:hover {
  display: flex;
  align-items: center;

  border: 1px solid #684fa9;
  background-color: rgb(232, 231, 228);
  border-radius: 3px;
  box-shadow: rgb(149, 146, 142) 0px 0px 0px 1px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* margin-top: 8px; */
  overflow-y: auto;

  width: 100%;
}

.tags-container .el-tag {
  margin-right: 8px;
  margin-bottom: 8px;
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow-y: auto;
  max-height: 160px;
}

.button-icon {
  height: 18px !important;
  width: 20px !important;
  margin-right: 5px !important;
}

.clrl {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #909399;
}
</style>
