<template>
  <div style="margin-top: 20px">
    <el-row :span="24">
      <el-col :span="20">
        <el-row>
          <el-col :gutter="0">
            <el-row> <h3>Task Summary</h3></el-row>
            <el-row class="content"
              ><p>
                View all of your task summaries. Any action being performed in
                your workflow is considered a task. Triggers are not calculated
                as tasks, and internal applications of Pabbly Connect, such as
                the filter, router, and formatter, are also not counted as
                tasks. Note that the task summary is only available for the last
                30 days.
              </p></el-row
            ></el-col
          >
          <el-row style="margin-top: 2%">
            <el-tabs
              v-model="activeName"
              @tab-click="handleClick"
              :span="24"
              :tab-width="400"
            >
              <el-tab-pane label="Task Summary" name="first">
                <el-row :gutter="15">
                  <el-col :span="6" style="margin-bottom: 20px">
                    <el-tooltip
                      effect="dark"
                      content="Number of tasks allotted to your account"
                      placement="top"
                    >
                      <el-card shadow="always">
                        <div style="display: flex">
                          <div
                            class="task-icon"
                            style="background-color: #cfe2ff"
                            type="plain"
                          >
                            <img
                              class="card-icon"
                              src="../assets/DashBoardIcons/TasksAllotted.png"
                              alt=""
                            />
                          </div>
                          <p class="cardTitle">
                            Tasks Allotted

                            <br />
                            <span
                              :class="[
                                'count',
                                tasksAllottedCount === 0
                                  ? 'zero-count'
                                  : 'non-zero-count',
                              ]"
                              style="
                                color: black;
                                font-weight: 600;
                                margin-bottom: -3px;
                                font-size: larger;
                              "
                            >
                              {{ tasksAllottedCount }}
                            </span>
                          </p>
                        </div>
                      </el-card>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="margin-bottom: 20px">
                    <el-tooltip
                      effect="dark"
                      content="Number of tasks consumed till now in your account"
                      placement="top"
                    >
                      <el-card shadow="always">
                        <div style="display: flex">
                          <div
                            class="task-icon"
                            style="background-color: #ccf4e2"
                          >
                            <img
                              class="card-icon"
                              src="../assets/DashBoardIcons/TasksConsumed.png"
                              alt=""
                            />
                          </div>
                          <p class="cardTitle">
                            Tasks Consumed
                            <br />
                            <span
                              style="
                                color: black;
                                font-weight: 600;
                                margin-bottom: -3px;
                                font-size: larger;
                              "
                            >
                              0
                            </span>
                          </p>
                        </div>
                      </el-card>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6" style="margin-bottom: 20px">
                    <el-tooltip
                      effect="dark"
                      content="Number of tasks remaining in your account"
                      placement="top"
                    >
                      <el-card shadow="always">
                        <div style="display: flex">
                          <div
                            class="task-icon"
                            style="background-color: rgb(249, 224, 224)"
                            type="plain"
                          >
                            <img
                              class="card-icon"
                              src="../assets/DashBoardIcons/TasksRemaining.png"
                              alt=""
                            />
                          </div>
                          <p class="cardTitle">
                            Tasks Remaining
                            <br />
                            <span
                              style="
                                color: black;
                                font-weight: 600;
                                padding: 1rem;
                                font-size: larger;
                              "
                            >
                              100
                            </span>
                          </p>
                        </div></el-card
                      ></el-tooltip
                    >
                  </el-col>
                  <el-col :span="6" style="margin-bottom: 20px">
                    <el-tooltip
                      effect="dark"
                      placement="top"
                      style="width: 40px; height: 30px"
                      content="Number of free tasks consumed in the last 30 days.
         your'e saving 50% on task usage by using esigns connect as it doesnt charge 
        tasks for triggers and internal application steps."
                    >
                      <el-card shadow="always">
                        <div style="display: flex">
                          <div
                            class="task-icon"
                            style="background-color: #cff4fc"
                            type="plain"
                          >
                            <img
                              class="card-icon"
                              src="../assets/DashBoardIcons/FreeTasksConsumed.png"
                              alt=""
                            />
                          </div>
                          <p class="cardTitle">
                            Free Tasks Consumed
                            <br />
                            <span
                              style="
                                color: black;
                                font-weight: 600;
                                font-size: larger;
                              "
                            >
                              0
                            </span>
                          </p>
                        </div>
                      </el-card>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <div>
                  <el-button type="warning, plain">
                    <p class="content">
                      Your tasks were reset on Jun 01, 2023 08:19:57 (GMT)
                    </p>
                  </el-button>
                </div>
              </el-tab-pane>
              <!-- connections -->

              <el-tab-pane label="Connections" name="second">
                <div class="tab-content">
                  <el-row :gutter="10" :span="24">
                    <el-col :span="8" style="margin-bottom: 20px">
                      <el-tooltip
                        effect="dark"
                        content="Number of tasks allotted to your account"
                        placement="top"
                      >
                        <el-card shadow="always">
                          <div style="display: flex">
                            <div
                              class="task-icon"
                              style="background-color: #cfe2ff"
                              type="plain"
                            >
                              <img
                                class="card-icon"
                                src="../assets/DashBoardIcons/TasksAllotted.png"
                                alt=""
                              />
                            </div>
                            <p class="cardTitle">
                              Total Connections

                              <br />
                              <span
                                :class="[
                                  'count',
                                  tasksAllottedCount === 0
                                    ? 'zero-count'
                                    : 'non-zero-count',
                                ]"
                                style="
                                  color: black;
                                  font-weight: 600;
                                  margin-bottom: -3px;
                                  font-size: larger;
                                "
                              >
                                {{ tasksAllottedCount }}
                              </span>
                            </p>
                          </div>
                        </el-card>
                      </el-tooltip>
                    </el-col>
                    <el-col :span="8" style="margin-bottom: 20px">
                      <el-tooltip
                        effect="dark"
                        content="Number of tasks consumed till now in your account"
                        placement="top"
                      >
                        <el-card shadow="always">
                          <div style="display: flex">
                            <div
                              class="task-icon"
                              style="background-color: #ccf4e2"
                            >
                              <img
                                class="card-icon"
                                src="../assets/DashBoardIcons/TasksConsumed.png"
                                alt=""
                              />
                            </div>
                            <p class="cardTitle">
                              Unique Connected Applications
                              <br />
                              <span
                                style="
                                  color: black;
                                  font-weight: 600;
                                  margin-bottom: -3px;
                                  font-size: larger;
                                "
                              >
                                0
                              </span>
                            </p>
                          </div>
                        </el-card>
                      </el-tooltip>
                    </el-col>
                    <el-col :span="8" style="margin-bottom: 20px">
                      <el-tooltip
                        effect="dark"
                        content="Number of tasks remaining in your account"
                        placement="top"
                      >
                        <el-card shadow="always">
                          <div style="display: flex">
                            <div
                              class="task-icon"
                              style="background-color: rgb(249, 224, 224)"
                              type="plain"
                            >
                              <img
                                class="card-icon"
                                src="../assets/DashBoardIcons/TasksRemaining.png"
                                alt=""
                              />
                            </div>
                            <p class="cardTitle">
                              Total Connected Workflows
                              <br />
                              <span
                                style="
                                  color: black;
                                  font-weight: 600;
                                  margin-bottom: -3px;
                                  font-size: larger;
                                "
                              >
                                100
                              </span>
                            </p>
                          </div></el-card
                        ></el-tooltip
                      >
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-card class="box-card">
                      <template #header>
                        <span style="float: left; font-weight: 600"
                          >Connection Details</span
                        >
                        <el-button
                          style="float: right; padding: 3px 0"
                          type="text"
                          >Filter</el-button
                        >
                      </template>
                      <div class="text item">
                        <h4>No Connections were found</h4>
                        <p class="cardTitle">
                          There may be no connections for your applied filter
                          conditions or you may not have created any connections
                          yet.
                        </p>
                      </div>
                    </el-card>
                  </el-row>
                </div>
              </el-tab-pane>

              <!-- team members -->
              <el-tab-pane label="Team Members" name="third">
                <div class="tab-content">
                  <el-row :gutter="10" :span="24">
                    <el-col :span="8" style="margin-bottom: 20px">
                      <el-tooltip
                        effect="dark"
                        content="Number of tasks allotted to your account"
                        placement="top"
                      >
                        <el-card shadow="always">
                          <div style="display: flex">
                            <div
                              class="task-icon"
                              style="background-color: #cfe2ff"
                              type="plain"
                            >
                              <img
                                class="card-icon"
                                src="../assets/DashBoardIcons/TasksAllotted.png"
                                alt=""
                              />
                            </div>
                            <p class="cardTitle">
                              Unique Team Members added

                              <br />
                              <span
                                :class="[
                                  'count',
                                  tasksAllottedCount === 0
                                    ? 'zero-count'
                                    : 'non-zero-count',
                                ]"
                                style="
                                  color: black;
                                  font-weight: 600;
                                  margin-bottom: -3px;
                                  font-size: larger;
                                "
                              >
                                {{ tasksAllottedCount }}
                              </span>
                            </p>
                          </div>
                        </el-card>
                      </el-tooltip>
                    </el-col>
                    <el-col :span="8" style="margin-bottom: 20px">
                      <el-tooltip
                        effect="dark"
                        content="Number of tasks consumed till now in your account"
                        placement="top"
                      >
                        <el-card shadow="always">
                          <div style="display: flex">
                            <div
                              class="task-icon"
                              style="background-color: #ccf4e2"
                            >
                              <img
                                class="card-icon"
                                src="../assets/DashBoardIcons/TasksConsumed.png"
                                alt=""
                              />
                            </div>
                            <p class="cardTitle">
                              Unique workflows shared by you
                              <br />
                              <span
                                style="
                                  color: black;
                                  font-weight: 600;
                                  margin-bottom: -3px;
                                  font-size: larger;
                                "
                              >
                                0
                              </span>
                            </p>
                          </div>
                        </el-card>
                      </el-tooltip>
                    </el-col>
                    <el-col :span="8" style="margin-bottom: 20px">
                      <el-tooltip
                        effect="dark"
                        content="Number of tasks remaining in your account"
                        placement="top"
                      >
                        <el-card shadow="always">
                          <div style="display: flex">
                            <div
                              class="task-icon"
                              style="background-color: rgb(249, 224, 224)"
                              type="plain"
                            >
                              <img
                                class="card-icon"
                                src="../assets/DashBoardIcons/TasksRemaining.png"
                                alt=""
                              />
                            </div>
                            <p class="cardTitle">
                              Workflows shared with you
                              <br />
                              <span
                                style="
                                  color: black;
                                  font-weight: 600;
                                  margin-bottom: -3px;
                                  font-size: larger;
                                "
                              >
                                100
                              </span>
                            </p>
                          </div></el-card
                        ></el-tooltip
                      >
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-card
                      class="box-card"
                      style="
                        border-left: 3px solid #409eff;
                        margin-bottom: 20px;
                      "
                    >
                      <template #header>
                        <span style="float: left; font-weight: 600"
                          >Points to remember</span
                        >
                      </template>
                      <div class="text item">
                        <div
                          class="cardTitle"
                          style="
                            text-align: left;
                            list-style-position: inside;
                            padding: 10px;
                          "
                        >
                          <ul>
                            <li>
                              You can add multiple members, and can share
                              multiple workflows with them.
                            </li>
                            <li>
                              Only shared workflows will be accessible to the
                              team members.
                            </li>
                            <li>
                              Tasks History specific to shared workflows will be
                              accessible.
                            </li>
                            <li>
                              Team members can add new app connections but they
                              can't edit or delete existing connections.
                            </li>
                            <li>
                              Team members won't be able to delete any shared
                              workflows in your account.
                            </li>
                            <li>
                              Team members can't access the billing information
                              of your account.
                            </li>
                            <li>
                              Team members can't access any sub-account-related
                              details of your account.
                            </li>
                            <li>
                              Team members will be able to create new workflows
                              in your account.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </el-card>

                    <el-card
                      class="box-card"
                      style="margin-bottom: 20px; text-align: left"
                    >
                      <template #header>
                        <span style="float: left; font-weight: 600"
                          >Team Members</span
                        >
                        <el-button style="float: right" type="plain"
                          >Add Team Member</el-button
                        >
                      </template>
                      <div class="text item">
                        <p class="cardTitle">
                          You have not added any team members yet. You can add a
                          team member and can share workflow access with them.
                        </p>
                      </div>
                    </el-card>

                    <el-card
                      class="box-card"
                      style="margin-bottom: 20px; text-align: left"
                    >
                      <template #header>
                        <span style="float: left; font-weight: 600"
                          >Workflows shared with you</span
                        >
                      </template>
                      <div class="text item">
                        <p class="cardTitle">
                          You don't have any shared workflows to access.
                        </p>
                      </div>
                    </el-card>
                  </el-row>
                </div>
              </el-tab-pane>

              <!-- api and webhooks -->
              <el-tab-pane label="API & Webhooks" name="fourth">
                <el-card
                  class="box-card"
                  style="text-align: left; margin-bottom: 20px"
                >
                  <template #header>
                    <span style="float: left; font-weight: 600">API</span>
                    <!-- <el-button style="float: right; padding: 3px 0" type="text">Filter</el-button> -->
                  </template>
                  <div class="text item">
                    <h4>Here's your ESIGNS Connect API Token</h4>
                    <el-input
                      placeholder="Click the Generate API Token button below to create one"
                      v-model="input2"
                    >
                      <template v-slot:append
                        ><div style="cursor: pointer">
                          <el-button type="primary">copy</el-button>
                        </div></template
                      >
                    </el-input>
                    <p class="cardTitle" style="font-size: 12px">
                      Enter the above API token for the Pabbly Connect Manager
                      app. When a new API token will be generated, the previous
                      API token will no longer be valid.
                    </p>
                    <el-button style="float: left; margin: 10px" type="primary"
                      >Generate API Token</el-button
                    >
                  </div>
                </el-card>
                <el-card
                  class="box-card"
                  style="border-left: 3px solid #409eff; margin-bottom: 20px"
                >
                  <template #header>
                    <span style="float: left; font-weight: 600">Webhooks</span>
                    <el-button style="float: right" type="plain"
                      >Add Webhook</el-button
                    >
                  </template>
                  <h5 style="text-align: left">Add Webhook URL</h5>
                  <p
                    class="cardTitle"
                    style="font-size: 12px; text-align: left"
                  >
                    Set up webhooks and receive notification for different
                    events..
                  </p>
                  <div class="text item" style="text-align: left">
                    <p class="cardTitle" style="list-style-position: inside">
                      All the events will be triggered on the added webhook URL.
                    </p>
                    <ul>
                      <li>
                        All the events will be triggered on the added webhook
                        URL.
                      </li>
                      <li>
                        You can select the events for specific webhooks URL.
                      </li>
                      <li>You can set up up to 5 webhooks URLs.</li>
                    </ul>
                  </div>
                </el-card>
              </el-tab-pane>

              <el-tab-pane label="Timezone" name="fifth">
                <el-card
                  class="box-card"
                  style="text-align: left; margin-bottom: 20px"
                >
                  <template #header>
                    <span style="float: left; font-weight: 600">Time Zone</span>
                    <!-- <el-button style="float: right; padding: 3px 0" type="text">Filter</el-button> -->
                  </template>
                  <div class="text item">
                    <h4>Select Time Zone</h4>
                    <el-autocomplete
                      v-model="state"
                      :fetch-suggestions="querySearchAsync"
                      placeholder="Please input"
                      @select="handleSelect"
                    ></el-autocomplete>
                    <p class="cardTitle" style="font-size: 12px">
                      View workflow and task execution times based on selected
                      time zone.
                    </p>
                    <el-button style="float: left; margin: 10px" type="primary"
                      >Save</el-button
                    >
                  </div>
                </el-card>
              </el-tab-pane>
            </el-tabs>
          </el-row>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [],
      state: "",
      timeout: null,
      activeName: "first",
    };
  },
  components: {},
  methods: {
    loadAll() {
      return [
        { value: "vue", link: "https://github.com/vuejs/vue" },
        { value: "element", link: "https://github.com/ElemeFE/element" },
        { value: "cooking", link: "https://github.com/ElemeFE/cooking" },
        { value: "mint-ui", link: "https://github.com/ElemeFE/mint-ui" },
        { value: "vuex", link: "https://github.com/vuejs/vuex" },
        { value: "vue-router", link: "https://github.com/vuejs/vue-router" },
        { value: "babel", link: "https://github.com/babel/babel" },
      ];
    },
    querySearchAsync(queryString, cb) {
      var links = this.links;
      var results = queryString
        ? links.filter(this.createFilter(queryString))
        : links;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 3000 * Math.random());
    },
    createFilter(queryString) {
      return (link) => {
        return (
          link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      console.log(item);
    },
  },
  mounted() {
    this.links = this.loadAll();
  },
};
</script>

<style lang="scss">
.topbar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.sidebar-wrapper {
  background-color: #eee;
  width: 200px;
  height: 100%;
  overflow-y: auto;
  list-style: none;
  position: fixed;
}
.task-icon {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  color: rgb(249, 224, 224);
}
.count {
  color: black;
  font-weight: 600;
  margin-bottom: -3px;
  font-size: larger;
  padding: 3px 5px;
}
.settings-page {
  position: relative;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 780px;
}
.box-card-with-border {
  border-left: 3px solid primary;
}
.tab-content {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
}
.text-item {
  float: left;
}
.content {
  font-size: 13px;
  color: #767676;
  p {
    white-space: pre-line;
    word-break: break-word;
    text-align: justify;
    text-justify: inter-word;
    margin-top: 0px;
  }
}
</style>
