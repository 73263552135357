<template>
  <div
    v-loading="loading"
    class="custom-loading-container"
    element-loading-spinner="el-loading-spinner"
    element-loading-background="rgba(0, 0, 0, 0.7)"
  >
    <el-row :gutter="10">
      <el-col :lg="8" :md="5" :sm="24" :xs="24">
        <div style="float: left; background-color: #3d4592; padding: 0 5px">
          <span style="color: white; font-weight: 600">
            Automation Workflows
          </span>
        </div>
      </el-col>
      <el-col :lg="4" :md="5" :sm="24" :xs="24">
        <el-dropdown
          size="medium"
          split-button
          type="primary"
          v-if="isMultiSelect"
        >
          Bulk Actions
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="deleteManyAutomations"
                >Delete Automation</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-col>
      <el-col :lg="4" :md="6" :sm="24" :xs="24">
        <el-select
          v-model="selectedStatus"
          @change="filterWorkflows"
          size="medium"
          class="searchable-dropdown"
        >
          <el-option label="All" value=""></el-option>
          <el-option label="Active" value="Active"></el-option>
          <el-option label="Inactive" value="Inactive"></el-option>
        </el-select>
      </el-col>
      <el-col :lg="4" :md="6" :sm="24" :xs="24">
        <el-input
          placeholder="Search"
          clearable
          @input="searchByName"
          v-model="searchString"
          size="medium"
        ></el-input>
      </el-col>
      <el-col :lg="4" :md="6" :sm="24" :xs="24">
        <el-button
          plain
          class="custom-button btn-clr"
          type="primary"
          @click="newAutomationflowModal = true"
          size="medium"
          >Create Automation Flow</el-button
        >
      </el-col>
      <el-dialog
        ref="newAutomationflowDialog"
        title="Create New Automation"
        v-model="newAutomationflowModal"
        class="doc-modal create-workflow-popup"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="destroyCreateModal"
        @keydown.enter="handleEnterKey"
      >
        <div class="mb-2">
          <!-- <el-input type="text" size="medium" placeholder="Automation Title" v-model="AutomationTitleName"></el-input> -->
          <el-form
            :model="automationData"
            ref="form"
            :rules="formRules"
            label-position="top"
          >
            <el-form-item label="Automation Title" prop="AutomationTitleName">
              <el-input
                type="text"
                size="medium"
                placeholder="Automation Title"
                v-model="automationData.AutomationTitleName"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div style="float: left">
          <el-checkbox v-model="isDependable">
            Is this automation dependent?
            <el-tooltip
              effect="light"
              placement="top"
              shadow="always"
              class="base-box-item"
              ><template #content>
                <div class="tooltip-class">
                  If you select this option, actions within this automation will
                  be linked,<br />
                  where the occurrence of one action depends on another event
                  happening.
                </div>
              </template>
              <el-icon>
                <InfoFilled />
              </el-icon>
            </el-tooltip>
          </el-checkbox>
        </div>
        <br /><br />
        <el-row>Description</el-row>
        <el-input
          v-model="automationData.description"
          :autosize="{ minRows: 5, maxRows: 8 }"
          type="textarea"
          placeholder="Ex: After added at kodefast add data into quickbooks"
        /><br /><br />
        <el-row style="display: flex" gutter="20">
          <el-radio-group v-model="radio" class="ml-4">
            <el-col :span="12">
              <el-card shadow="never" class="custom-card">
                <div class="custom-radio">
                  <el-row>
                    <el-col :span="20">
                      <el-radio label="1">
                        <span>Event Based Automation</span>
                      </el-radio>
                      <div
                        style="
                          text-wrap: wrap;
                          text-overflow: hidden;
                          whitespace: normal;
                        "
                        class="mt-1"
                      >
                        <span class="radio-label-sub-text">
                          Triggers actions in an app based on events. For
                          example, sends a follow-up when a new lead is added in
                          Kodefast</span
                        >
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <img
                        src="@/assets/appImage.png"
                        style="height: 50px; width: 50px"
                      />
                    </el-col>
                  </el-row>
                  <div class="bottom clearfix"></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card shadow="never" class="custom-card">
                <div class="custom-radio">
                  <el-row>
                    <el-col :span="20">
                      <el-radio label="2">
                        <span>Schedule based Automation</span>
                      </el-radio>
                      <div
                        style="
                          text-wrap: wrap;
                          text-overflow: hidden;
                          whitespace: normal;
                        "
                        class="mt-1"
                      >
                        <span class="radio-label-sub-text"
                          >Triggers pre-defined sequences of actions at specific
                          times or at regular intervals.</span
                        >
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <img
                        src="@/assets/scheduleI.png"
                        style="height: 40px; width: 40px"
                      />
                    </el-col>
                  </el-row>
                  <div class="bottom clearfix"></div>
                </div>
              </el-card>
            </el-col>
          </el-radio-group>
        </el-row>
        <template #footer>
          <div class="dialog-footer">
            <el-button size="medium" @click="newAutomationflowModal = false"
              >Cancel</el-button
            >
            <el-button
              type="primary"
              size="medium"
              @click="openDrawer"
              :disabled="!automationData.AutomationTitleName.length"
              >Confirm</el-button
            >
          </div>
        </template>
      </el-dialog>
      <el-drawer
        v-model="drawer"
        size="38%"
        class="drawer-with-left-title"
        title="Configure"
        :with-header="true"
      >
        <el-form
          ref="ruleFormRef"
          :model="scheduleForm"
          :rules="rulesDrawer"
          class="p-1"
          status-icon
          label-position="top"
        >
          <el-form-item prop="schedule" label="Choose Schedule Type" required>
            <el-select
              size="medium"
              class="schedule"
              v-model="scheduleForm.schedule"
            >
              <el-option
                v-for="option in options"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="date"
            label="Start Date & Time"
            required
            v-if="
              ['daily', 'once', 'weekly', 'monthly', 'yearly'].includes(
                scheduleForm.schedule
              )
            "
          >
            <el-date-picker
              v-model="scheduleForm.date"
              type="datetime"
              placeholder="Select date and time"
              :shortcuts="shortcuts"
              size="medium"
              class="date"
              style="width: 100%"
            />
          </el-form-item>
          <el-form-item
            label="Time Zone"
            prop="selectedTimeZone"
            required
            v-if="
              ['daily', 'once', 'weekly', 'monthly', 'yearly'].includes(
                scheduleForm.schedule
              )
            "
          >
            <vue-timezone-select
              v-model="scheduleForm.selectedTimeZone"
              size="medium"
            ></vue-timezone-select>
          </el-form-item>
          <el-form-item
            prop="days"
            label="Days of Week"
            required
            v-if="scheduleForm.schedule === 'daily'"
          >
            <el-checkbox-group v-model="scheduleForm.days" align="left">
              <el-checkbox label="Sunday" />
              <el-checkbox label="Monday" />
              <el-checkbox label="Tuesday" />
              <el-checkbox label="Wednesday" />
              <el-checkbox label="Thursday" />
              <el-checkbox label="Friday" />
              <el-checkbox label="Saturday" />
            </el-checkbox-group>
          </el-form-item>
          <!-- weekly -->
          <el-form-item
            prop="interval"
            label="Repetition Interval"
            required
            v-if="scheduleForm.schedule === 'weekly'"
            class="mt-4"
          >
            Every &nbsp;
            <el-select size="small">
              <el-option></el-option>
            </el-select>
            &nbsp; week(s) on &nbsp;
            <el-select size="small">
              <el-option></el-option>
            </el-select>
          </el-form-item>

          <!-- monthly -->
          <el-form-item
            v-if="scheduleForm.schedule === 'monthly'"
            label="Day of Month"
            prop="dayOfMonth"
            required
          >
            <el-date-picker
              v-model="scheduleForm.dayOfMonth"
              type="date"
              placeholder="Select day"
              :shortcuts="shortcuts"
              size="medium"
              class="date"
              style="width: 100%"
            />
          </el-form-item>
          <el-form-item
            v-if="scheduleForm.schedule === 'monthly'"
            label="Months"
            prop="months"
            required
          >
            <el-select-v2
              v-model="scheduleForm.months"
              filterable
              :options="monthsOptions"
              style="width: 100%"
              multiple
              size="medium"
              placeholder="select MOnth"
            />
          </el-form-item>

          <!-- yearly -->
          <el-form-item
            prop="interval"
            label="Repetition Interval"
            required
            v-if="scheduleForm.schedule === 'yearly'"
            class="mt-4"
          >
            Every &nbsp;
            <el-select size="small">
              <el-option></el-option>
            </el-select>
            &nbsp; year(s) on &nbsp;
            <el-select size="small">
              <el-option></el-option>
            </el-select>
          </el-form-item>
          <!-- once --> </el-form
        ><br /><br />
        <el-divider />
        <div style="flex: auto" align="right">
          <el-button @click="drawer = false">cancel</el-button>
          <el-button type="primary" @click="submitForm">confirm</el-button>
        </div>
      </el-drawer> </el-row
    ><br />
    <el-scrollbar wrap-style="width: 100%;">
      <el-table :data="allWorkFlows" class="rounded-table">
        <el-table-column prop="select" label="Select" width="80">
          <template #header>
            <el-checkbox
              style="position: absolute; top: -1px; left: 10px"
              v-model="selectAll"
              @change="handleSelectAll(selectAll)"
            >
            </el-checkbox>
          </template>
          <template #default="{ row }">
            <el-checkbox
              v-model="row.isArchived"
              @change="selectEachRow(row)"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="Name">
          <template v-slot="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.name"
              placement="top"
            >
              <div
                class="table-cell"
                @mouseover="hoverZoom(rowIndex)"
                @mouseleave="unHoverZoom()"
              >
                <i
                  v-if="hoveredIndex === rowIndex"
                  class="far fa-user fa-lg"
                ></i>
                <span class="name">{{
                  row.name.length > 15
                    ? row.name.slice(0, 15) + "..."
                    : row.name
                }}</span>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column prop="description" label="Description">
          <template v-slot="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.description"
              placement="top"
            >
              <div
                class="table-cell"
                @mouseover="hoverZoom(rowIndex)"
                @mouseleave="unHoverZoom()"
              >
                <i
                  v-if="hoveredIndex === rowIndex"
                  class="far fa-envelope fa-lg"
                ></i>
                <span class="description">
                  {{
                    row.description.length > 15
                      ? row.description.slice(0, 15) + "..."
                      : row.description
                  }}
                </span>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="Created At" width="250">
          <template v-slot="{ row }">
            <div
              class="table-cell"
              @mouseover="hoverZoom(rowIndex)"
              @mouseleave="unHoverZoom()"
            >
              <i
                v-if="hoveredIndex === rowIndex"
                class="far fa-envelope fa-lg"
              ></i>
              <span>{{ getLocalTime(row.created_at) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="lastUpdated" label="Last Updated" width="250">
          <template v-slot="{ row }">
            <div
              class="table-cell"
              @mouseover="hoverZoom(rowIndex)"
              @mouseleave="unHoverZoom()"
            >
              <i
                v-if="hoveredIndex === rowIndex"
                class="far fa-envelope fa-lg"
              ></i>
              <span>{{ getLocalTime(row.updated_at) }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Action" prop="action">
          <template v-slot="scope">
            <el-dropdown>
              <el-button type="plain" size="mini" class="action-button btn-clr">
                Actions<el-icon class="el-icon--right"><arrow-down /></el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="changeSchedule(scope.row)">
                    <el-icon>
                      <View />
                    </el-icon>
                    change Schedule</el-dropdown-item
                  >
                  <el-dropdown-item @click="gotoWorkflowsPage(scope.row)">
                    <el-icon>
                      <EditPen />
                    </el-icon>
                    Edit</el-dropdown-item
                  >
                  <el-dropdown-item @click="renameWorkflowbyId(scope.row)">
                    <el-icon>
                      <Edit />
                    </el-icon>
                    Rename</el-dropdown-item
                  >
                  <el-dropdown-item @click="deleteWorkflow(scope.row)">
                    <el-icon>
                      <Delete />
                    </el-icon>
                    Delete</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>
  </div>
  <div>
    <el-pagination
      class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItems"
    />
  </div>
</template>
<script>
import store from "@/store/index";
import VueTimezoneSelect from "vue-timezone-select";
import { mapGetters } from "vuex";
import filters from "@/mixins/filters";
import axios from "../../store/modules/axiosInstance";
import appConfig from "../../config/app";
export default {
  components: {
    VueTimezoneSelect,
  },
  data() {
    return {
      isMultiSelect: false,
      selectAll: false,
      multipleDelete: [],
      allWorkFlows: [],
      showDropdown: false,
      searchString: "",
      AutomationTitleName: null,
      automationInfo: null,
      loading: true,
      newAutomationflowModal: false,
      selectedStatus: "",
      hovered: false,
      isDependable: false,
      radio: "1",
      drawer: false,
      textarea2: "",
      rulesDrawer: {
        schedule: [
          {
            required: true,
            message: "schedule type is required",
            trigger: "blur",
          },
        ],
        date: [
          { required: true, message: "date is required", trigger: "blur" },
        ],
        weekly: [
          { required: true, message: "date is required", trigger: "blur" },
        ],
        monthly: [
          { required: true, message: "date is required", trigger: "blur" },
        ],
        yearly: [
          { required: true, message: "date is required", trigger: "blur" },
        ],
        once: [
          { required: true, message: "date is required", trigger: "blur" },
        ],
        startDateMonth: [
          { required: true, message: "date is required", trigger: "blur" },
        ],
      },
      scheduleForm: {
        schedule: "once",
        date: null,
        days: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        interval: null,
        dayOfMonth: null,
        months: ["January"],
        selectedTimeZone: null,
      },
      options: [
        { label: "Daily", value: "daily" },
        { label: "weekly", value: "weekly" },
        { label: "Monthly", value: "monthly" },
        { label: "Yearly", value: "yearly" },
        { label: "Once", value: "once" },
      ],
      monthsOptions: [
        { label: "January", value: "January" },
        { label: "February", value: "February" },
        { label: "March", value: "March" },
        { label: "April", value: "April" },
        { label: "May", value: "May" },
        { label: "June", value: "June" },
        { label: "July", value: "July" },
        { label: "August", value: "August" },
        { label: "September", value: "September" },
        { label: "October", value: "October" },
        { label: "November", value: "November" },
        { label: "December", value: "December" },
      ],
      automationData: {
        AutomationTitleName: "",
        description: "",
        createdAt: "",
        updated_at: "",
      },
      formRules: {
        AutomationTitleName: [
          {
            required: true,
            message: "Automation Title is required",
            trigger: "blur",
          },
        ],
      },
      hoveredIndex: -1,
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    ...mapGetters("globalApis", [
      "getAllFlowsByUserId",
      "getDeleteFlow",
      "getRenameFlow",
      "getAutomationFlow",
      "getAutomationFlowById",
      "getWorkflowsByPagination",
      "getAutomationFlowBySearch",
      "getAutomationStatus",
      "getScheduleAutomation",
    ]),
  },
  mounted() {
    // this.getAllWorkflowsOfUser()
    this.getworkflows(), this.getAutomationStatus;
  },
  mixins: [filters],
  methods: {
    handleSelectAll(value) {
      this.allWorkFlows.map((flow, index) => {
        if (value) {
          flow.isArchived = true;
          this.multipleDelete.push(flow._id);
          this.isMultiSelect = true;
        } else {
          flow.isArchived = false;
          this.multipleDelete.slice(index, 1);
          this.isMultiSelect = false;
        }
      });
    },
    selectEachRow(flow) {
      if (flow.isArchived) {
        this.multipleDelete.push(flow._id);
      } else {
        let indexToRemove = this.multipleDelete.indexOf(flow._id);
        this.multipleDelete.splice(indexToRemove, 1);
      }
      if (this.multipleDelete.length) {
        this.isMultiSelect = true;
      } else {
        this.isMultiSelect = false;
      }
    },
    async deleteManyAutomations() {
      try {
        this.$confirm("Are you sure want to delete", "Warning", {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        })
          .then(async () => {
            let params = { workFlows: this.multipleDelete };
            this.loading = true;
            let response = await axios.post(
              `${appConfig.app.connectorAppApi}/automationFlow/delete-many-workflows`,
              params
            );
            this.loading = false;
            if (response && response.data && response.data.status) {
              await this.getworkflows();
              this.$notify({
                title: "Success",
                message: "Workflows deleted successfully.",
                type: "success",
              });
            }
          })
          .catch(() => {});
      } catch (err) {
        console.log("err", err);
      }
    },
    truncateText(text) {
      // Truncate text to show only 150 characters
      if (text && text.length > 15) {
        return text.slice(0, 30) + "...";
      }
      return text;
    },
    rowStyle() {
      return {
        height: "80px",
        color: "0E1216",
        fontSize: "14px",
        fontWeight: 500,
      };
    },
    hoverZoom(index) {
      this.hoveredIndex = index;
    },
    unHoverZoom() {
      this.hoveredIndex = -1;
    },
    async getAllWorkflowsOfUser() {
      this.loading = true;
      await store.dispatch("globalApis/fetchAllFlowsByUserId");
      this.loading = false;
      this.allWorkFlows = this.getAllFlowsByUserId;
    },
    gotoWorkflowsPage(row) {
      this.$router.push({
        path: `/connector/workflow/${row._id}`,
      });
    },
    // formatDate(timestamp) {
    //   const date = new Date(timestamp);
    //   const options = {
    //     day: '2-digit',
    //     month: 'short',
    //     hour: '2-digit',
    //     minute: '2-digit',
    //   };
    //   return date.toLocaleString('en-US', options);
    // },
    async searchByName() {
      const searchString = this.searchString.toLowerCase();
      let params = {
        value: searchString,
      };
      this.loading = true;
      await store.dispatch("globalApis/searchWorkflowByName", params);
      this.loading = false;
      this.allWorkFlows = this.getAutomationFlowBySearch.data;
    },
    async createAutomationflow() {
      let params = {
        workflow_name: this.automationData.AutomationTitleName,
        description: this.automationData.description,
        isDependable: this.isDependable,
      };
      this.loading = true;
      await store.dispatch("globalApis/createAutomationFlow", params);
      this.loading = false;
      if (
        this.getAutomationFlow &&
        this.getAutomationFlow.data &&
        this.getAutomationFlow.data._id
      ) {
        this.automationInfo = this.getAutomationFlow.data;
        return this.getAutomationFlow.data._id;
      } else {
        return;
      }
    },
    async destroyCreateModal() {
      this.newAutomationflowModal = false;
    },
    deleteWorkflow(row) {
      this.$confirm("Are you sure you want to delete the Automation flow?")
        .then(async () => {
          try {
            this.loading = true;
            await store.dispatch("globalApis/deleteWorkflow", row._id);
            this.getworkflows();
            this.loading = false;
            this.$notify({
              title: "Success",
              message: "Workflow deleted successfully.",
              type: "success",
            });
          } catch (error) {
            this.$notify({
              title: "Error",
              message: "Error in deleting workflow",
              type: "error",
            });
          }
        })
        .catch(() => {});
    },
    async renameWorkflowbyId(row) {
      this.dialog(row);
    },
    async filterWorkflows() {
      try {
        let params;
        if (this.selectedStatus == "Active") {
          params = {
            status: true,
          };
          this.loading = true;
          await store.dispatch("globalApis/automationFlowByStatus", params);
          this.loading = false;
          if (this.getAutomationStatus.data) {
            this.allWorkFlows = this.getAutomationStatus.data;
          }
        } else if (this.selectedStatus == "Inactive") {
          params = {
            status: false,
          };
          this.loading = true;
          await store.dispatch("globalApis/automationFlowByStatus", params);
          this.loading = false;
          if (this.getAutomationStatus.data) {
            this.allWorkFlows = this.getAutomationStatus.data;
          }
        } else {
          this.getworkflows();
        }
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: "Error in updating the status",
        });
      }
    },

    async dialog(row) {
      this.$prompt("Enter a new name", "Rename", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        inputPlaceholder: "New name",
        inputValue: row.name,
      })
        .then(async ({ value }) => {
          let params = {
            automationId: row._id,
            newAutomationName: value,
          };
          this.loading = true;
          await store.dispatch("globalApis/renameAutomationById", params);
          this.loading = false;
          row.name = this.getRenameFlow.data.name;
          this.$notify({
            title: "Success",
            message: "Item renamed successfully.",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "Error",
            message: "Error in renaming Automation flow",
          });
        });
    },
    async openDrawer() {
      let response = await this.createAutomationflow();
      if (response) {
        if (this.radio == "2") {
          this.newAutomationflowModal = false;
          this.drawer = true;
        } else {
          this.$router.push({
            path: `/connector/workflow/${this.automationInfo._id}`,
          });
        }
        this.$notify.success({
          title: "Success",
          message: "Automation Flow created successfully.",
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in creating Automation flow",
        });
      }
    },
    async submitForm() {
      let payload = {
        scheduleObject: this.scheduleForm,
        automationId: this.automationInfo._id,
      };
      this.loading = true;
      await store.dispatch("globalApis/scheduleAutomation", payload);
      this.loading = false;
      if (this.getScheduleAutomation.data) {
        if (this.getScheduleAutomation.data.isEdit) {
          this.drawer = false;
        } else {
          this.$router.push({
            path: `/connector/workflow/${this.automationInfo._id}`,
          });
        }
        this.$notify.success({
          title: "Success",
          message: this.getScheduleAutomation.message,
        });
      } else {
        this.$notify.error({
          title: "error",
          message: this.getScheduleAutomation.message,
        });
      }
    },
    async changeSchedule(row) {
      this.loading = true;
      await store.dispatch("globalApis/fetchAutomationFlowById", {
        id: row._id,
      });
      this.automationInfo = this.getAutomationFlowById.data;
      this.loading = false;
      if (!this.automationInfo.schedule) {
        this.$notify({
          title: "Warning",
          message: "No schedule for this flow",
          type: "warning",
        });
      } else {
        this.scheduleForm = row.schedule;
        this.drawer = true;
      }
    },
    async handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getworkflows(1);
    },
    async handleCurrentChange(page) {
      this.currentPage = page;
      this.getworkflows(page);
    },
    async getworkflows(page) {
      let params = {
        pageNo: page || this.currentPage,
        limit: this.pageSize,
      };
      this.loading = true;
      await store.dispatch("globalApis/getWorkflowsByPagination", params);
      this.loading = false;
      const response = this.getWorkflowsByPagination;
      if (response && response.data) {
        this.allWorkFlows = response.data;
        this.totalItems = response.total || 0;
      } else {
        this.allWorkFlows = [];
        this.totalItems = 0;
      }
    },
  },
};
</script>
<style>
.custom-loading-container .el-loading-spinner {
  position: absolute;
  top: 50;
  left: -110;
}
.pagination {
  text-align: center;
  margin-top: 10px;
  margin-left: 25%;
}
</style>
.pagination {
  text-align: center;
  margin-top: 10px;
  margin-left: 25%;
}