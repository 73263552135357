<template>
  <el-row class="top-bar">
    <el-col :span="14">
      <div class="logo">
        <img src="@/assets/Logo-white.png" class="logo" alt="Logo" />
      </div>
    </el-col>
    <el-col :span="6">
      <el-row class="profile-details" justify="end">
        <el-col :span="4">
          <img src="@/assets/Notifications.svg" />
        </el-col>
        <el-col :span="4">
          <img src="@/assets/man.png" alt="Profile Picture" />
        </el-col>
        <el-col :span="6">
          <el-dropdown trigger="hover" @visible-change="handleDropdownVisibleChange">
            <el-button type="text">
              <a href="#" class="user-name">{{ userName }}</a> &nbsp;
              <el-icon color="white"><ArrowDown /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="openProfileView">settings</el-dropdown-item>
                <el-dropdown-item @click="logout">Logout</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TopbarDefault",
  components: {
    // SidebarDefault
  },
  data() {
    return {
      userName: null,
      isDropdownVisible: false,
      isCollapse: true,
      isSidebarVisible: true,
    };
  },
  computed: {
    ...mapGetters("user", ["getLoginResponse"]),
  },
  mounted() {
    this.bindUserInfo();
  },
  methods: {
    async bindUserInfo() {
      let user = JSON.parse(localStorage.getItem("user-info"));
      this.userName = user.userDetails.first_name;
    },
    handleDropdownVisibleChange(visible) {
      this.isDropdownVisible = visible;
    },

    async logout() {
      // await axios
      // .post("https://connect.esigns.io/api/users/login", this.userDetails)
      // .then(() => {
      this.$router.push(this.$route.query.redirect || "/");

      // console.log(this.userDetails);
      // console.log(response.data);
      // })
      // .catch((error) => {
      //   console.log(error);
      // });
    },
    openProfileView() {
      this.$router.push("/profileBody");
    },
  },
};
</script>

<style scoped>
.top-bar {
  background-color: #6b52ae;
  color: #fff;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: relative;
}

.logo img {
  width: 70px;
  padding-top: 5px;
  padding-left: 4px;
  float: left;
}

.profile-details {
  display: flex;
  align-items: center;
}

.profile-details img {
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

a {
  text-decoration: none;
}

.dropdown {
  position: relative;
}

.dropdown ul {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  /* border: 1px solid #ccc; */
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
}

.dropdown ul li {
  padding: 10px;
}

.dropdown ul li:hover {
  background-color: #eee;
}

.dropdown:hover ul {
  display: block;
}
.user-name {
  font-size: 16px;
  color: #ffffff;
  font-family: "Inter", sans-serif;
}
</style>
