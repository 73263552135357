<template>
  <el-tab-pane
    label="Filter setup & testing"
    class="trigger_size"
    name="filterSetup"
    key="filterSetup"
  >
    <div
      v-loading="loading"
      element-loading-spinner="el-loading-spinner"
      element-loading-background="rgba(0, 0, 0, 0.7)"
    >
      <el-form label-position="top" style="width: 100%">
        <!-- Loop to display "And" rules -->

        <!-- <div class="dash"></div> -->

        <!-- Loop to display "Or" rules -->
        <div
          v-for="(orGroup, groupIndex) in action.filter_rules"
          :key="groupIndex"
          :class="[
            'artboardTop',
            'mt',
            groupIndex % 2 === 0 ? 'even-group' : 'odd-group',
          ]"
        >
          <div v-for="(rule, index) in orGroup.rules" :key="index" class="artboardTop mt">
            <div class="flex-container">
              <p v-if="index === 0" class="rule-text">Only continue if</p>
              <p v-else class="rule-text">And</p>
              <el-button
                v-if="index > 0"
                type="danger"
                @click="removeRule(index, orGroup.rules)"
                class="remove-rule-button"
                >X Remove</el-button
              >
            </div>
            <el-form-item>
              <el-input
                class="tag-textarea"
                v-model="rule.field.value"
                clearable
                @focus="showCard(rule)"
                type="text"
                placeholder="Choose Fields.."
                readonly
                :autosize="{ minRows: 1, maxRows: 5 }"
              />
              <el-card v-show="rule.field.isMapping" style="width: 100%">
                <template v-slot:header>
                  <div class="clearfix">
                    <span>Select Map Fields</span>
                    <el-button style="float: right" type="text" @click="hideCard(rule)"
                      >X</el-button
                    >
                  </div>
                </template>
                <el-collapse v-model="accordion1Expanded">
                  <el-collapse-item v-for="(fields, index) in mappingFields" :key="index">
                    <template #title>
                      <img :src="fields.logo" alt="logo" class="button-icon" />
                      {{ fields.app_name }}
                    </template>

                    <div class="button-row">
                      <el-button
                        v-for="(item, index) in fields.fields"
                        :key="index"
                        class="button-with-icon"
                        @click="toggleTag(item, rule)"
                      >
                        <img :src="fields.logo" alt="logo" class="button-icon" />
                        <template v-if="!item.showFullText">
                          <span class="clrf">{{ item.label }}</span> :
                          <span class="clrl">
                            {{
                              item.value && item.value.length > 25
                                ? item.value.slice(0, 25) + "..."
                                : item.value
                            }}
                          </span>
                          <span v-if="item.value && item.value.length > 25">
                            <a @click="toggleTextFortag(item)" class="clr">More</a>
                          </span>
                        </template>
                        <template v-else>
                          <span class="clrf">{{ item.label }}</span> :
                          <span class="clrl">{{ item.value }}</span>
                          <a @click="toggleTextFortag(item)" class="clr">Less</a>
                        </template>
                      </el-button>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-card>
              <el-select
                class="tag-textarea mt"
                v-model="rule.condition"
                placeholder="Please select a condition"
              >
                <!-- Text Conditions -->
                <el-option
                  v-for="(condition, index) in conditions"
                  :label="condition.label"
                  :value="condition.value"
                  :key="index"
                ></el-option>
              </el-select>

              <el-input
                class="tag-textarea mt"
                v-model="rule.value"
                clearable
                type="text"
                placeholder="Enter Value.."
                :autosize="{ minRows: 1, maxRows: 5 }"
              />
            </el-form-item>
            <el-button
              type="primary"
              v-if="index === rules.length - 1"
              @click="addRule(index, orGroup.rules)"
              >+ Adds</el-button
            >
          </div>
        </div>
        <el-button
          class="mt"
          v-if="shouldShowAddOrGroupButton"
          type="primary"
          @click="addOrGroup"
          >+ Add "Or" rule group</el-button
        >
      </el-form>
    </div>
  </el-tab-pane>
</template>

<script>
export default {
  props: {
    action: {
      type: Object,
      required: true,
    },
    mappingFields: {
      type: Array,
    },
  },
  data() {
    return {
      isMapping: false,
      accordion1Expanded: false,
      loading: false,
      rules: [
        {
          field: {
            label: "",
            key: "",
            value: "",
            appName: "",
            logo: "",
            automationId: "",
            actionId: "",
            isMapping: false,
          },
          condition: "",
          value: "",
        },
      ],
      filter_rules: this.action.filter_rules,
      conditions: [
        { label: "(Text) Contains", value: "text_contains" },
        { label: "(Text) Does Not Contain", value: "text_does_not_contain" },
        { label: "(Text) Exactly Matches", value: "text_exactly_matches" },
        { label: "(Text) Does Not Exactly Match", value: "text_does_not_exactly_match" },
        { label: "(Text) Is In", value: "text_is_in" },
        { label: "(Text) Is Not In", value: "text_is_not_in" },
        { label: "(Text) Starts With", value: "text_starts_with" },
        { label: "(Text) Does Not Start With", value: "text_does_not_start_with" },
        { label: "(Text) Ends With", value: "text_ends_with" },
        { label: "(Text) Does Not End With", value: "text_does_not_end_with" },
        { label: "(Text) Is Empty", value: "text_is_empty" },
        { label: "(Text) Is Not Empty", value: "text_is_not_empty" },
        { label: "(Number) Is Equal To", value: "number_is_equal_to" },
        { label: "(Number) Is Not Equal To", value: "number_is_not_equal_to" },
        { label: "(Number) Is Greater Than", value: "number_is_greater_than" },
        { label: "(Number) Is Less Than", value: "number_is_less_than" },
        {
          label: "(Number) Is Greater Than Or Equal To",
          value: "number_is_greater_than_or_equal_to",
        },
        {
          label: "(Number) Is Less Than Or Equal To",
          value: "number_is_less_than_or_equal_to",
        },
        { label: "(Date/Time) Is Equal To", value: "datetime_is_equal_to" },
        { label: "(Date/Time) Is Not Equal To", value: "datetime_is_not_equal_to" },
        { label: "(Date/Time) Is Before", value: "datetime_is_before" },
        { label: "(Date/Time) Is After", value: "datetime_is_after" },
        { label: "(Boolean) Is True", value: "boolean_is_true" },
        { label: "(Boolean) Is False", value: "boolean_is_false" },
        { label: "(Boolean) Is Value Exisists", value: "variable_has_value" },
        { label: "(Boolean) Is No Value Exisists", value: "variable_has_no_value" },
      ],
    };
  },
  computed: {
    shouldShowAddOrGroupButton() {
      return (
        this.filter_rules.length === 0 ||
        this.filter_rules[this.filter_rules.length - 1].showFields
      );
    },
  },
  methods: {
    showCard(field) {
      field.field.isMapping = true;
      this.accordion1Expanded = true;
    },
    hideCard(field) {
      field.field.isMapping = false;
    },
    addRule(index, rules) {
      rules.push({
        field: {
          label: "",
          key: "",
          value: "",
          appName: "",
          logo: "",
          automationId: "",
          actionId: "",
          isMapping: false,
        },
        condition: "",
        value: "",
      });
    },
    removeRule(index, rules) {
      rules.splice(index, 1);
    },
    toggleTag(item, rule) {
      rule.field = item;
    },
    addOrGroup() {
      this.filter_rules.push({
        rules: [
          {
            field: {
              label: "",
              key: "",
              value: "",
              appName: "",
              logo: "",
              automationId: "",
              actionId: "",
              isMapping: false,
            },
            condition: "",
            value: "",
          },
        ],
      });
    },
  },
};
</script>

<style scoped>
.artboardTop {
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap !important;
}
.mt {
  margin-top: 10px;
}
.tag-textarea {
  width: calc(100% - 2px);
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  padding: 8px;
  resize: vertical;
}
.flex-container {
  display: flex;
  align-items: center;
  width: 100%;
}
.rule-text {
  margin: 0;
  flex-grow: 1;
}
.remove-rule-button {
  margin-bottom: 1%;
  margin-right: 1%;
}
.tags-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow-y: auto;
  width: 100%;
}
.tags-container .el-tag {
  margin-right: 8px;
  margin-bottom: 8px;
}
.button-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow-y: auto;
  max-height: 160px;
}
.button-icon {
  height: 18px !important;
  width: 20px !important;
  margin-right: 5px !important;
}
.dash {
  border-style: dashed double none;
  border-color: rgb(215, 213, 210);
  margin-top: 2%;
}
.remove-rule-button,
.remove-group-button {
  margin-left: 10px;
}
.mt {
  margin-top: 2%;
}
.even-group {
  background: rgba(255, 255, 255, 0.8); /* Light gray background for even groups */
}
.odd-group {
  background-color: lightgray; /* Lighter gray background for odd groups */
}
</style>
