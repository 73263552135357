import { createStore } from "vuex";
import userModule from "./modules/user";
import globalApis from "./modules/store";
import ssoModule from "./modules/sso";

const store = createStore({
  modules: {
    user: userModule,
    globalApis: globalApis,
    sso: ssoModule,
  },
  strict: false,
});
export default store;
